import {
  ActionLogsListViewComponent,
  CustomerProfilesListViewComponent,
  ForgotPasswordViewComponent,
  LoginViewComponent,
  OfferedServicesListViewComponent,
  OrderCardAttributeTypesListViewComponent,
  OrdersListViewComponent,
  OrderSourcesListViewComponent,
  OrderStatusesListViewComponent,
  PrivacyPolicyViewComponent,
  ResetPasswordViewComponent,
  StaffProfilesListViewComponent,
  TermsOfServiceViewComponent,
} from '@aa/angular/core';
import { OrderDetailViewComponent } from '@aa/angular/staff';
import { Route } from '@angular/router';

export const appRoutes: Route[] = [
  {
    path: 'login',
    component: LoginViewComponent,
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordViewComponent,
  },
  {
    path: 'reset-password/:userId/:token',
    component: ResetPasswordViewComponent,
  },
  {
    path: 'terms-of-service',
    component: TermsOfServiceViewComponent,
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyViewComponent,
  },
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full',
  },
  {
    path: 'orders',
    component: OrdersListViewComponent,
  },
  {
    path: 'orders/:id',
    component: OrderDetailViewComponent,
  },
  {
    path: 'customers',
    component: CustomerProfilesListViewComponent,
  },
  {
    path: 'staff',
    component: StaffProfilesListViewComponent,
  },
  {
    path: 'offered-services',
    component: OfferedServicesListViewComponent,
  },
  {
    path: 'order-statuses',
    component: OrderStatusesListViewComponent,
  },
  {
    path: 'order-sources',
    component: OrderSourcesListViewComponent,
  },
  {
    path: 'order-card-attribute-types',
    component: OrderCardAttributeTypesListViewComponent,
  },
  {
    path: 'action-logs',
    component: ActionLogsListViewComponent,
  },
];
