import {
  angularCoreServices,
  AuthInterceptor,
  AutocompleteFormFieldComponent,
  DateTimePickerFormFieldComponent,
  RepeatingSectionFormFieldComponent,
} from '@aa/angular/core';
import {
  RequestedServicesFormFieldComponent,
  staffAppEffects,
  staffAppMetaReducers,
  staffAppReducers,
} from '@aa/angular/staff';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptorsFromDi,
} from '@angular/common/http';
import {
  ApplicationConfig,
  importProvidersFrom,
  provideZoneChangeDetection,
  isDevMode,
} from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyMatToggleModule } from '@ngx-formly/material/toggle';
import { appRoutes } from './app.routes';
import { provideClientHydration } from '@angular/platform-browser';
import { provideServiceWorker } from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
  providers: [
    provideClientHydration(),
    ...angularCoreServices,
    provideAnimations(),
    provideHttpClient(withFetch(), withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    provideStore(staffAppReducers, {
      metaReducers: staffAppMetaReducers,
    }),
    provideEffects(...staffAppEffects),
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(appRoutes),
    importProvidersFrom(
      FormlyModule.forRoot({
        types: [
          {
            name: 'autocomplete',
            component: AutocompleteFormFieldComponent,
            wrappers: ['form-field'],
          },
          {
            name: 'requested-services',
            component: RequestedServicesFormFieldComponent,
          },
          {
            name: 'repeating-section',
            component: RepeatingSectionFormFieldComponent,
          },
          {
            name: 'date-time',
            component: DateTimePickerFormFieldComponent,
            wrappers: ['form-field'],
          },
        ],
        validationMessages: [
          {
            name: 'required',
            message: 'This field is required.',
          },
          {
            name: 'email',
            message: 'Must be a valid email',
          },
          {
            name: 'min-password',
            message:
              'Must be at least 6 characters long and have at least 1 special character',
          },
          {
            name: 'confirm-password',
            message: 'Must match password',
          },
        ],
        validators: [
          {
            name: 'existing-resource',
            validation: (fieldControl) =>
              typeof fieldControl.value == 'object' && fieldControl.value.id,
          },
          {
            name: 'min-password',
            validation: (fieldControl) => {
              const newPassword = fieldControl.value;
              const specialCharacterRegex = new RegExp(/[^A-Za-z0-9]/g);
              return (
                newPassword &&
                newPassword.length > 5 &&
                specialCharacterRegex.test(newPassword)
              );
            },
          },
        ],
      }),
      FormlyMaterialModule,
      FormlyMatToggleModule,
    ),
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always', appearance: 'outline' },
    },
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
