<div class="content">
  <h2>Cards</h2>

  @if (cardsLoading$ | async) {
    <mat-spinner></mat-spinner>
  }

  <div class="order-cards">
    @for (card of cards$ | async; track card.id) {
      <div
        class="card-preview card"
        [ngClass]="{ selected: (currentCard$ | async)?.id == card.id }">
        <p class="label">
          <strong>{{ card.label }}</strong>
        </p>
        <img (click)="setCurrentCard(card)" [src]="card.frontImageURL" />
      </div>
    }
  </div>

  @if ((currentCard$ | async) != null) {
    <div class="card-editor">
      <div class="section">
        <h2>Images</h2>
        <div class="images">
          <img
            [src]="(currentCard$ | async)!.frontImageURL"
            (click)="handleImageClick(false)"
            width="200"
            alt="Card Front" />
          <img
            [src]="(currentCard$ | async)!.backImageURL"
            (click)="handleImageClick(true)"
            width="200"
            alt="Card Back" />
        </div>
      </div>

      <div class="section">
        <h2>Attributes</h2>
        @if (aiLoading$ | async) {
          <div class="ai-loading">
            <p>Detecting Attributes</p>
            <mat-spinner></mat-spinner>
          </div>
        }
        @if (!(hideForm$ | async)) {
          <form [formGroup]="form">
            <formly-form [form]="form" [fields]="fields" [model]="model">
            </formly-form>
          </form>
        }

        <div class="actions">
          <button mat-flat-button color="warn" (click)="cancel()">
            Cancel
          </button>
          <button
            mat-flat-button
            color="primary"
            [disabled]="!form.valid || !form.dirty"
            (click)="save()">
            Save
          </button>
        </div>
      </div>
    </div>
  }
</div>
